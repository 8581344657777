// Masthead
//
// Super small header above the content for site name and short description.

.masthead {
  padding-top: var(--spacer);
  padding-bottom: var(--spacer);
  margin-bottom: var(--spacer-3);
}

.masthead-title {
  margin-bottom: 0;

  a {
    color: inherit;
    text-decoration: none;
  }

  small {
    font-weight: 400;
    opacity: 0.5;
  }
}

.masthead-logo {
  display:inline-block;

  &:hover {
    .cheese {
      filter: brightness(125%);
    }
  }

  .cheese {
    transition:.2s;
  }

  .base {
    fill: #fff;
  }

  .crust {
    fill: #fff;
  }

  .pepp {
    fill: #fff;
  }
}



