// Layout
//
// Styles for managing the structural hierarchy of the site.

.container {
  max-width: 45rem;
  padding-left: var(--spacer-2);
  padding-right: var(--spacer-2);
  margin-left:  auto;
  margin-right: auto;
}

footer {
  margin-top: var(--spacer-3);
  margin-bottom: var(--spacer-3);

  .copyright {
    opacity:0.5;

  }

  .arr {
    opacity:0.5;

    @media (max-width:500px) {
      display:none;
    }
  }

  .nav {
    float: right;
    word-spacing: 1rem;

    small:last-child {
      @media (max-width:400px) {
        display: none;
      }
    }
  }

}
