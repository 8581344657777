//homepage signup

#newsletter-signup-wrap {
    .email {
        min-width:220px;
        height:26px;
    }

    .button {
        color:#fff;
        background-color: #836dbc;
        cursor: pointer;
        height:26px;
    }
}

// post signups

// Email Signup Forms

#post-newsletter-signup {
	padding: 1rem;
	margin-bottom:2rem;
	margin-top: 2rem;
	transition: background-color 1s ease;
	background-color: var(--gray-800);
	color: var(--white);
	font-size:1.2rem;
	text-align: center;
	
	&:hover { 
		.button {
			box-shadow: 3px 3px var(--black);
		}

		#download-thumbnail {
            top: -20px;			
		}
	}

	.newsletter-caption {
		font-size:1rem;
		font-style:italic;
		margin-top:1rem;
		display:block;
	}

	#download-thumbnail {
        position:relative;
        left: 50%;
        transform: translateX(-50%);    
        top:0;
        transition: top 1s ease;
        z-index: 99;
		box-shadow: 3px 3px var(--black);
		margin:0;
		margin-bottom:1rem;
	}

	.mc-field-group {
		min-height:36px;
	}

	#mc_embed_signup_scroll {
		margin-bottom:0.5rem;
		position:relative;
	}

	#mce-EMAIL {	  
	  transition: border 1s ease;
	  padding: 5px;
	  width:100%;
	  border: 1px solid var(--gray-1);
	  height: 30px;

	  @media (min-width: 700px) { 	    
	    padding: 5px;
		width:60%;
	  }
	}

	.button {
		line-height: 28px;
		background-color: var(--accent-purple-light);
		transition: box-shadow 1s ease;
		color: var(--black);
		border: none;
		cursor: pointer;
		min-width:100px;
		margin-top:5px;
		height: 30px;

		@media (max-width: 699px) { 	 		   
	      width:100%;
	  }

		&:hover {
			box-shadow: 6px 6px var(--black);
		}

		&:active {
			box-shadow: 2px 2px;
		}
	}
}

